<template>
  <div
      class="d-flex"
      v-if="whole != '0' && !hidePrice"
  >
    <span class="font-weight-medium"> {{ approximately ? 'от ' + getWhole : getWhole }} </span>
    <small
        class="font-weight-medium "
        v-if="remains != '00'"
    >
      <sup>{{ remains }}</sup>
    </small>
    <span class="font-weight-medium">₽</span>
  </div>
  <div v-else>
    <span class="font-weight-medium">{{ textOnZero }}</span>
  </div>
</template>

<script>

export default {
  name: 'PriceTotal',
  data() {
    return {
      whole: 1,
      remains: '00',
    }
  },
  props: {
    price: Number,
    count: {
      default: 1,
      type: Number
    },
    approximately: {
      default: false,
      type: Boolean
    },
    hidePrice: {
      default: false,
      type: Boolean
    },
    textOnZero: {
      type: String,
      default: 'Нет цены'
    }
  },
  watch: {
    whole() {
      return 0
    },
    remains() {
      return '00'
    }
  },
  created() {
    let price_ = this.price * this.count
    let whole_ = Math.trunc(price_ / 100) || 0
    let remains_ = Math.trunc(price_ % 100) || 0
    this.whole = whole_
    this.remains = (remains_ < 10 ? '0' + remains_ : remains_).toString()
  },
  computed: {
    getWhole() {
      return new Intl.NumberFormat('ru-RU').format(this.whole)
    }
  }


}
</script>