export { HistoryApiService, AppointmentsApiService, AnalysesApiService, CreateApiService, shop, orders }

import axios from 'axios'
import store from '@/store'

const clientAPI = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  // responseType: 'json',
});

// Api service for History page
const HistoryApiService = {
  async getHistory() {
    const userId = store.getters.activeUser
    try {
      let resp = await axios.get(window.config.apiUrl + '/appointment/get-list?idCustomer=' + userId)
      return resp.data
    } catch (err) {
      return null
    }
  },
  async getCoupons() {
    // try {
    //   let resp = await axios.get(window.config.apiUrl + '/coupons?idAppointment=' + idAppointment)
    //   return resp.data
    // } catch (err) {
    //   return null
    // }
  },
  async getServices() {
    // try {
    //   let resp = await axios.get(window.config.apiUrl + '/coupon-services?idCoupon=' + idCoupon)
    //   return resp.data
    // } catch (err) {
    //   return null
    // }
  },
  async getExaminations() {
    // try {
    //   let resp = await axios.get(window.config.apiUrl + '/examinations?idCoupon=' + idCoupon)
    //   return resp.data
    // } catch (err) {
    //   return null
    // }
  }
}

// Api service for Appointments page
const AppointmentsApiService = {
  async getAppointments() {
    const userId = store.getters.activeUser
    try {
      let resp = await axios.get(window.config.apiUrl + '/appointments?idCustomer=' + userId)
      return resp.data
    } catch (err) {
      return null
    }
  }
}

// Api service for Analyzes page
const AnalysesApiService = {
  async getAnalyses() {
    const userId = store.getters.activeUser
    try {
      let resp = await axios.get(window.config.apiUrl + '/examination/get-analyses-examinations?idCustomer=' + userId)
      return resp.data
    } catch (err) {
      return null
    }
  }
}

// Api service for Create appointment page
const CreateApiService = {
  async getSpecials() {
    try {
      let resp = await axios.get(window.config.apiUrl + '/specials')
      return resp.data
    } catch (err) {
      console.log(err)
      return null
    }
  },
  async getUsers(selectedSpecial) {
    try {
      let resp = await axios.get(window.config.apiUrl + '/user-specials?idSpecial=' + selectedSpecial)
      return resp.data
    } catch (err) {
      console.log(err)
      return null
    }
  },
  async getUserInfo(idUser) {
    try {
      let resp = await axios.get(window.config.apiUrl + '/user/get-user-info?idUser=' + idUser + '&idClient=' + process.env.VUE_APP_CLIENT+'')
      return resp.data
    } catch (err) {
      console.log(err)
      return null
    }
  },
  async getUserDates() {
    // try {
    //   let resp = await axios.get(window.config.apiUrl + '/appointment-helper/get-user-dates?idUser=' + selectedDoctor)
    //   return resp.data
    // } catch (err) {
    //   console.log(err)
    //   return null
    // }
  },
  async getUserTimes() {
    // try {
    //   let resp = await axios.get(
    //    window.config.apiUrl +
    //       'appointment-helper/get-user-time?selectedDoctor=' +
    //       selectedDoctor +
    //       '&selectedDate=' +
    //       selectedDate
    //   )
    //   return resp.data
    // } catch (err) {
    //   console.log(err)
    //   return null
    // }
  },
  async getUserServices() {
    // try {
    //   let resp = await axios.get(window.config.apiUrl + 'distance/get-services?idUser=' + idUser + '&idClient='+process.env.VUE_APP_CLIENT+'')
    //   return resp.data
    // } catch (err) {
    //   console.log(err)
    //   return null
    // }
  },
  async getScheduleTypes() {
    // let url = window.config.apiUrl + 'user/get-schedule-user-date?user=' + user + '&idClient='+process.env.VUE_APP_CLIENT+'&date=' + date + ''
    // let data = {
    //   user: user,
    //   date: date,
    //   idClient: process.env.VUE_APP_CLIENT
    // }
    // try {
    //   let resp = await axios({
    //     url: url,
    //     data,
    //     method: 'POST'
    //   })

    //   return resp.data
    // } catch (err) {
    //   console.log(err)
    //   return null
    // }
  },
  async createAppointmentChat(data) {
    let today = new Date()
    let currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    data.currentDate = currentDate
    let url = window.config.apiUrl + 'chat/add-coupons'
    try {
      axios({
        url: url,
        data,
        method: 'POST'
      })
    } catch (err) {
      console.log(err)
    }
  },
  async createAppointment(data) {
    let today = new Date()
    let currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    data.currentDate = currentDate
    let url = window.config.apiUrl + 'appointments'
    try {
      axios({
        url: url,
        data,
        method: 'POST'
      })
    } catch (err) {
      console.log(err)
    }
  }
}

const shop = {
  async getPersonalTokenShop() {    
    const userId = store.getters.activeUser
    let url = window.config.apiUrl + '/auth/shop'
    try {
      let data = await axios.post(url,{
        token: localStorage.getItem('token')
      })
      return data        
    } catch (err) {
      console.log(err)
    }
  }
}


const orders = {
  async get() {    
    try {
      const {data} = await axios.get(window.config.apiUrl + '/orders', {token: store.getters.token})
      return data 
    } catch (err) {
      console.log(err)
      return null
    }
  },
  async pay(number) {
    //return await axios.post(window.config.apiUrl + '/orders/pay', {token: store.getters.token,'number':number})
    
    try {
      const {data} = await axios.post(window.config.apiUrl + '/orders/pay', {token: store.getters.token,'number':number})
      return data 
    } catch (err) {
      return null
    }
  },
  async delete(number, reason) {
    //return await axios.post(window.config.apiUrl + '/orders/pay', {token: store.getters.token,'number':number})
    
    try {
      const {data} = await axios.post(window.config.apiUrl + '/orders/delete', {token: store.getters.token,'number':number, 'reason': reason})
      return data 
    } catch (err) {
      return null
    }
  }

}