<template>
  <div>
    <v-card
        class="mx-auto"
        elevation="0"
    >
      <v-card-text class="pb-0">
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex flex-column align-items-start">
            <v-btn
                v-if="!this.order.payStatus || this.order.payStatus < 1"
                text
                color="error"
                class="mb-2"
                x-small
                @click.stop="dialogDelete = true"
            >
              Отменить заказ
            </v-btn>
            <div>
              <v-chip
                  :color="order.status.color"
                  small
              >
                {{ order.status.name }}
              </v-chip>
            </div>
            <div v-if="showCode && order.code">
              <span v-if="order.code" class="badge badge-info mt-2">Код получения: {{ order.code }}</span>
            </div>
          </div>

          <span class="d-flex flex-column align-items-end">
                        <span>№ {{ order.number }}</span>                        
                        <div class="text-right">                            
                            <span :class="'ml-2 badge badge-' + payStatusColor ">{{ payStatusLabel }}</span> 
                        </div>
                    </span>
        </div>
        <div class="row">
          <div class="col-12 col-md-7 pb-0">
            <div class="text--primary">
              Дата оформления: {{ dateOrder }}
              <span v-if="order.filial && !order.delivery"><br/> Пункт выдачи: {{
                  order.filial.name
                }} {{ order.filial.address ? order.filial.address : '' }}</span>
              <br/>Количество товаров: <b>{{ order.items.length }}</b>
              <span v-if="order.comment"><br/> Комментарий: {{ order.comment }}</span>
            </div>
          </div>
          <div class="col-12 col-md-5 pb-0 ">
            <div v-if="!order.missing" class="d-flex justify-content-md-end align-baseline">
              <span class="subtitle-1 mr-2 ">Итого: </span>
              <div class="text-h6 font-weight-medium text--primary">
                <PriceTotal :price="totalPrice"
                            :approximately="isApproximately"/>
              </div>
            </div>
            <div v-else class="d-flex justify-content-md-end align-baseline">
              <span class="subtitle-1 mr-2 ">Товара из списка нет в наличии</span>
            </div>
            <div v-if="!order.missing && order.delivery" class="d-flex justify-content-md-end align-baseline">
              <span class="subtitle-1 mr-2 ">Доставка: </span>
              <div class="text-subtitle-2 font-weight-medium text--primary">
                <PriceTotal :price="order.delivery.price" :text-on-zero="approved ? 'Бесплатно': 'Нет цены'"/>
              </div>
            </div>
          </div>
        </div>

        <div v-if="order.delivery" class="grey lighten-3 p-3 rounded mt-2">
          <h6>Доставка
            <span class="badge badge-secondary">{{ order.delivery.alias }}</span>
          </h6>
          <div class="text-dark">{{
              order.delivery.id === 'courier' ? 'Доставка курьером по адресу' : 'Пункт выдачи'
            }}:
            <b>{{ order.delivery.address }}</b>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="blue-grey lighten-2"
            text
            @click="show = !show"
        >
          Подробнее
          <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <div v-if="!order.missing && order.payStatus < 1">
          <div>
            <v-chip
                v-if="isApproximately"
                small
            >
              Ожидайте звонка менеджера
            </v-chip>
          </div>

          <v-btn
              v-if="!isApproximately && order.status.tag == 'expectPayment' && isAcquiring && (!order.delivery || (order.delivery && order.delivery.approved))"
              color="success"
              :disabled="loadingPay"
              text
              @click="pay()"
          >
            <span v-if="loadingPay"><i class="fas fa-circle-notch fa-spin"></i></span>
            <span v-else>Оплатить</span>
          </v-btn>
        </div>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">

          <v-card-text>
            <div class="grey lighten-4 p-3 rounded">
              <div :key="index" v-for="(orderItem,index) in order.items">
                <OrderItem :item="orderItem" :payStatus="order.payStatus"/>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>

    </v-card>
    <div class="pr-2 pl-2">
      <v-alert
          v-model="alert"
          border="left"
          close-text="Close Alert"
          color="deep-purple accent-4"
          dark
          dismissible
      >
        {{ alertText }};
      </v-alert>
    </div>
    <v-dialog
        v-model="dialogDelete"
        max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление
        </v-card-title>

        <v-card-text>
          Вы уверены что хотите удалить заказ №{{ order.number }} c товарами:
          <ul>
            <li :key="index" v-for="(orderItem,index) in order.items">
              {{ orderItem.name }};
            </li>
          </ul>

          <v-text-field
              v-model="reason"
              hide-details="auto"
              label="Пожалуйста, укажите причину отмены заказа"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="green darken-1"
              text
              @click="dialogDelete = false"
          >
            Отмена
          </v-btn>

          <v-btn
              color="error darken-1"
              text
              @click="deleteOrder()"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import OrderItem from '@/components/orders/OrderItem.vue'
import PriceTotal from '@/components/orders/PriceTotal.vue'
import {orders} from '@/services/api'

export default {
  name: 'OrderLine',
  components: {
    OrderItem,
    PriceTotal
  },
  data() {
    return {
      alert: false,
      alertText: 'Ошибка при формировании счёта',
      show: false,
      loadingPay: false,
      dialogDelete: false,
      reason: '',
      isAcquiring: false
    }
  },
  props: {
    order: Object
  },
  created() {
    this.isAcquiring = (this.$store.getters.STATIC.shopAcquiringActive && this.$store.getters.STATIC.acquiringActive)
  },
  computed: {
    dateOrder() {
      return moment(this.order.date).format('DD.MM.YYYY HH:mm:ss')
    },
    payStatusLabel() {
      let payStatusLabel = 'Счёт не создан'
      switch (this.order.payStatus) {
        case 0 : {
          payStatusLabel = 'Не оплачено';
          break;
        }
        case 1 : {
          payStatusLabel = 'Оплачено';
          break;
        }
        case 2 : {
          payStatusLabel = 'Возврат';
          break;
        }
      }
      return payStatusLabel
    },
    payStatusColor() {
      let payStatusColor = 'secondary'
      switch (this.order.payStatus) {
        case 0 : {
          payStatusColor = 'warning';
          break;
        }
        case 1 : {
          payStatusColor = 'success';
          break;
        }
        case 2 : {
          payStatusColor = 'danger';
          break;
        }
      }
      return payStatusColor
    },
    showCode() {
      return this.order.status.tag != 'completed' && this.order.status.tag != 'cancel' && this.order.status.tag != 'expectPayment'
    },
    itemsPrice() {
      return this.order.items?.reduce((accumulator, item) => {
        return accumulator + (item.hidePrice ? 0 : (item.price * item.total))
      }, 0)
    },
    totalPrice() {
      return this.itemsPrice + (this.order.delivery ? this.order.delivery.price : 0)
    },
    approved() {
      return this.order.delivery && this.order.delivery.approved
    },
    isApproximately() {
      return this.order.items?.reduce((accumulator, item) => {

        return !accumulator ? item.approximately : true
      }, 0)
    },
    getCountAll() {
      return this.order.items?.reduce((accumulator, item) => {
        return accumulator + item.total
      }, 0)
    },

  },
  methods: {
    async pay() {
      this.loadingPay = true
      try {
        const pay = await orders.pay(this.order.number)
        if (pay.status == 'success') {
          window.top.location.replace(pay.message)
        } else {
          this.alertText = pay.message
          this.alert = true;
        }
      } catch (e) {
        return null
      }
      this.loadingPay = false
    },
    async deleteOrder() {
      this.dialogDelete = false
      try {
        const result = await orders.delete(this.order.number, this.reason)
        if (result.status == 'success') {
          this.$emit('delete', this.order.number);
        } else {
          this.alertText = result.message
          this.alert = true;
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>