<template>
  <div>
    <template v-if="historyLoading">
      <v-skeleton-loader type="list-item-three-line@3" ></v-skeleton-loader>
    </template>
    <template v-else>
      <div class="d-flex flex-column" style="gap: 1rem">
        <template v-for="historyRow of drawHistory(history || [])">
          <v-card class="pb-4 list-item" rounded="0" flat :key="historyRow.id">
            <div class="d-flex flex-row-reverse align-items-center">
              
              <div class="d-flex flex-row align-items-center" style="width: max-content;">
                <div style="width: max-content">
                  <v-icon v-if="historyRow.amount > 0" color="green" small>fas fa-plus</v-icon>
                  <v-icon v-else color="orange" class="text--darken-3" small>fas fa-minus</v-icon>
                </div>
                <div class="d-flex align-items-center justify-content-center text-h6 font-weight-bold" :class="{'orange--text text--darken-3': (historyRow.amount < 0), 'green--text': (historyRow.amount > 0)}">
                  {{ Math.abs(historyRow.amount) }}<span>₽</span>
                </div>
              </div>
              <div class="mr-2 flex-grow-1 d-flex flex-column flex-wrap justify-content-center">
                <div v-if="historyRow.reason">
                  {{ historyRow.reason }}
                </div>
                <small v-if="historyRow.activeDate && isActive(historyRow.activeDate)">
                  <v-icon color="orange" class="text--darken-3" left x-small>fas fa-lock</v-icon>
                  Можно использовать после :&nbsp;<span>{{ formatDate(historyRow.activeDate) }}</span>
                </small>
                <small>
                  <v-icon color="primary" left x-small>far fa-clock</v-icon>
                  Дата {{ (historyRow.amount > 0) ? 'начисления' : 'списания' }}:&nbsp;<span>{{ formatDate(historyRow.date) }}</span>
                </small>
                <small v-if="historyRow.amount > 0">
                  <template v-if="historyRow.expire">
                    <v-icon color="orange" class="text--darken-3" left x-small>fas fa-fire-alt</v-icon>Сгорают:&nbsp;<span>{{ formatDate(historyRow.expire) }}</span>
                  </template>
                  <template v-else>
                    <v-icon color="primary" left x-small>fas fa-shield-alt</v-icon>Несгораемые
                  </template>
                </small>
              </div>
            </div>
          </v-card>
        </template>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: "BonusHistory",
  
  computed: {
  },
  
  data: () => ({
    historyLoading: false,
    history: null
  }),
  
  methods: {
    drawHistory(history) {
      return history.map( val => ({
        ...val,
        amount: (val.debit !== null) ? val.debit : -val.credit,
        data: val.dateCreate,
        expire: val.dateExpire
      }) ).filter( val => val.amount );
    },
    
    // Форматирование даты
    formatDate(date) {
      let mdate = this.$moment(date);
      if (!mdate.isValid()) {
        return 'неизвестно';
      }
      return mdate.format('D MMMM YYYY');
    },
    
    // Являются ли бонусы активными
    isActive(date) {
      let mdate = this.$moment(date);
      if (!mdate.isValid() || mdate.unix() <= this.$moment().unix()) {
        return false;
      }
      return true;
    }
  },
  
  mounted() {
    this.historyLoading = true;
    this.$http(window.config.apiUrl + '/customer-bonuses/get-history')
      .then( ({data}) => {
        if (data.status == 'success') {
          this.history = data.history;
          this.historyLoading = false;
        }
      })
      .catch( error => console.log(error) );
  },
}

</script>

<style scoped>

.list-item {
  border-bottom: 1px solid lightgrey;
}

.list-item:last-child {
  border-bottom: none;
}

</style>