<template>
  <div>
    <ProgressBar :progress="1"/>
    <div class="choice-title">
      <h5>Выберите филиал</h5>
    </div>

    <v-card
        v-for="filial in STATIC.filials"
        :key="filial.id"
        elevation="2"
        class="mx-auto mb-3 text-center"
        max-width="400"
        outlined
        @click="selectFilial(filial.id)"
    >

        <v-card-title class="justify-content-center">
            {{filial.name}}
        </v-card-title>

        <v-card-subtitle>
            <i class="fas fa-map-marker-alt primary--text mr-1"></i>&nbsp; {{filial.address}}
        </v-card-subtitle>

    </v-card>
    <v-card
        elevation="2"
        class="mx-auto mb-3 text-center"
        max-width="400"
        outlined
        @click="selectFilial(null)"
    >
        <v-card-title class="justify-content-center">
            Любой
        </v-card-title>
    </v-card>
  </div>
</template>

<script>

import router from '../../router'
import moment from 'moment'

import ProgressBar from '../../components/appointment/ProgressBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: "ClientSelect",
  components: {
    ProgressBar,
  },
  created(){
    if (!this.STATIC.filials || this.STATIC.filials.length <= 1) {
        this.$store.commit('setFilial', null);
        this.$router.push({
          path: '/direction',
          query: {
            idCustomer: this.$route.query.idCustomer
          }
        });
    }
  },
  methods: {
    selectFilial(id) {
        this.$store.commit('setFilial', id);
        this.$router.push({
          path: '/direction',
          query: {
            idCustomer: this.$route.query.idCustomer
          }
        });
    }
  },
  computed: {
    ...mapGetters(['getUser', 'STATIC']),
  }


}
</script>

<style scoped>
  .client-choice{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
  }
  .card-avatar{
    display: flex;
    justify-content: center;
    width: 10%;
    color: var(--primary-color);
  }
  .choice-title{
    text-align: center;
    margin: 1rem 0;
  }
</style>