<template>
  <v-container>
    <v-row>
        <v-col
          md="10"
          offset-md="1"
          sm="12"
          xs="12"
        >
          <v-text-field  @input="inputChangeHandler" label="Введите ФИО врача или специальность" v-model="input" clearable v-if="$mq!=='sm'"></v-text-field>
          <v-text-field  @input="inputChangeHandler" label="ФИО врача или специальность" v-model="input" clearable v-else></v-text-field>
          <div class="loader" v-if="loading">
            <v-skeleton-loader
              v-bind="attrs"
              type="date-picker"
            ></v-skeleton-loader>
            <v-skeleton-loader
              v-bind="attrs"
              type="date-picker"
            ></v-skeleton-loader>
          </div>

          <p class="label" v-if="users">Врачи</p>
          <div class="list" v-if="users.length">
            <div class="list-item" v-for="(user, id) in users" :key="id" @click="userClickHandler(user.id)">
              <div class="user-avatar">
                <v-img
                  :src="getSrc(user.id)"
                  contain
                  max-height="70"
                  max-width="70"
                  alt="Специалист"
                ></v-img>
              </div>
              <div class="user-info">
                <div class="user-fio" v-html="formatResult(user.surname+' '+user.name+' '+user.middleName)"></div>
                <span v-for="(special, index) in user.specials" :key="index" class="user-special">
                  {{special}}<span v-if="index!==user.specials.length-1">,</span> 
                </span>
              </div>
            </div>
          </div>
          <p class="label" v-if="users && !users.length">
            <i class="fas fa-exclamation-circle"></i> 
            Не найдено подходящих врачей
          </p>

          <p class="label" v-if="specials">Специальности</p>
          <div class="list" v-if="specials">
            <div class="list-item " v-for="(special, id) in specials" :key="id">
              <div class="special" @click="specialClickHandler(special.id, special.specialName)" v-html="formatResult(special.specialName)">
                <i class="fas fa-caret-right"></i>
              </div>
            </div>
          </div>
          <p class="label" v-if="specials && !specials.length">
            <i class="fas fa-exclamation-circle"></i> 
            Не найдено подходящих специальностей
          </p>

        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import router from '../../router'

export default {
  data(){
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      loading: false,
      users: '',
      specials: '',
      input: '',
      timerId: null,
      q: '',
    }
  },
  props: {
    // функция отображения списка врачей для текущей даты
    usersShowHandler: Function,
    // функция изменения фильтра поиска врачей по специальности
    setIdSpecial: Function,
  },
  methods: {
    // обработчик изменения ввода инпута
    inputChangeHandler(e){
    // функция задержки обработки ввода
    this.inputDebounce(e)
    },
    // функция задержки обработки ввода
    inputDebounce(e){
      // отчиска предыдущего таймера
      clearTimeout(this.timerId)
      // если данные из инпута удалены
      if (!e) {
        this.timerId = setTimeout(() => {
          this.users = ''
          this.specials = ''
          this.loading = false
          this.setIdSpecial('')
          this.usersShowHandler(true)
        }, 1000)
        return
      }
      // устанавливаем новый таймер на обработку введенных данных
      this.timerId = setTimeout(() => {
        this.loading = true 
        this.getData(e)
        this.usersShowHandler(false)
      }, 1000)
    },
    // получение объекта с пользователями и специальностями
    getData(q){
      this.q = q
      this.$store.dispatch('getUsersSpecials', q)
      .then(res => {
        this.users = res.data.users
        this.specials = res.data.specials
        this.loading = false
      })
      .catch(err => {
        console.log(err)
        this.loading = false
      })
      .finally(() => this.loading = false)
    },
    // обработка выбора специалиста
    userClickHandler(id){
      this.$store.commit('setUserId', id)
      localStorage.userId = id
      // переадресация на страницу с конкретным врачем
      router.push({
        path: '/user',
        query: {
          id: id,
          idCustomer: this.$route.query.idCustomer
        }
      });
    },
    // обработка выбора специальности
    specialClickHandler(id, name){
      this.users = ''
      this.specials = ''
      this.input = name
      this.usersShowHandler(true)
      this.setIdSpecial(id)
      // прокрутка страницы вверх
      window.scrollTo(0,0)
    },
    // функция выделения совпадений по вводимому в инпут тексту
    formatResult(val){
      let q = this.q.toLowerCase()
      let arr = val.split(q)
      if (arr.length < 2) {
        q = q[0].toUpperCase() + q.slice(1)
        arr = val.split(q)
      }
      let res = ''
      for (let i=0; i<arr.length; i++){
        res += arr[i]
        if (i === arr.length-1) continue
        res += `<b>${q}</b>`
      }
      return res
    },
    getSrc(userId){
      return window.config.apiUrl +  '/file/get-user-photo?idUser=' + userId
    }
  },
}
</script>

<style scoped>
  .list{
    display: flex;
    flex-wrap: wrap;
  }
  .label{
    color: rgb(124, 124, 124);
  }
  .list-item{
    cursor: pointer;
    padding: 10px;
    display: flex;
    min-width: 50%;
    margin-bottom: 10px;
  }
  .list-item .special{
    padding: 10px;
    /* border-left: 1px solid #ccc; */
  }
  .list-item .special:hover{
    color: var(--primary-color);
  }
  .user-info{
    margin-left: 10px;
  }
  .user-avatar{
    min-width: 70px;
    height: 70px;
  }
  .user-fio:hover{
    color: var(--primary-color);
  }
  .user-special{
    color: #ccc;
  }
</style>